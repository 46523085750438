import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

const PostLink = ({ post }) => (
  <li>
    <Link to={post.frontmatter.path} style={{ fontSize: '1.25em' }}>
      {post.frontmatter.date} [{post.frontmatter.title}]
    </Link>
    <span style={{ fontSize: '.75rem' }}>
      {' '}
      /{' '}
      {post.frontmatter.category ? post.frontmatter.category.toLowerCase() : ''}
    </span>
  </li>
)

export default ({
  data: {
    allMarkdownRemark: { edges },
    site: { siteMetadata },
  },
}) => {
  const _title = `${siteMetadata.title} | projects`
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)
  return (
    <Layout>
      <Helmet>
        <title>{siteMetadata.title} | projects</title>
        <meta name="twitter:title" content={_title} />
        <meta property="og:title" content={_title} />
      </Helmet>
      <div
        style={{
          padding: '1.45rem 1.0875rem',
          width: '100%',
          maxWidth: 960,
          margin: '0 auto',
        }}
      >
        <h1 style={{ color: '#4b4b4b' }}>Projects</h1>
        <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
          {Posts}
        </ul>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fileAbsolutePath: { regex: "/projects/" } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MM.DD.YYYY")
            path
            category
          }
        }
      }
    }
  }
`
